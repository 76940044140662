.experience-container {
  border-radius: 20px;
  padding: 45px;
  height: 200px;
  margin: 14px;
  width: 85vw;
  max-width: 1000px;
  align-content: center;
}

.role-title h3 {
  font-weight: bold;
  font-size: 20px;
}

.role-title span {
  color: #ec5d3a;
}

.role-dates {
  font-size: 12px;
  color: #a9a9a9;
}

.role-description {
  color: #444444;
  font-size: 14px;
}

.logo img {
  width: 60px;
  margin-right: 20px;
  border-radius: 50%;
}

.right {
  margin-right: -50px;
  margin-left: 50px;
}

.left {
  margin-right: 50px;
  margin-left: -50px;
}

.experience-intro {
  padding-top: 50px;
  padding-bottom: 28px;
}

.experience-intro h2 {
  font-size: 2rem;
  font-weight: 600;
}

.experience-intro p {
  color: #a9a9a9;
}

.job-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 991px) {
  .experience-container {
    width: 84vw;
  }
  .right {
    margin-right: -30px;
    margin-left: 30px;
  }

  .left {
    margin-right: 30px;
    margin-left: -30px;
  }
}

@media (max-width: 780px) {
  body {
    width: 120%;
  }

  .experience-container {
    height: auto;
    border-radius: 20px;
    padding: 15px;
    margin: 6px;
    width: 110vw;
    align-content: center;
  }

  .experience-content {
    padding: 10px;
    padding-top: 10px;
  }
  .logo img {
    width: 40px;
    margin-right: 10px;
  }
}
