html {
  scroll-behavior: smooth;
}
body {
  color: #404247;
  font-family: "Amiko";
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-backdrop {
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

.blue {
  background-color: #f5f9fa;
  animation: float1 6s ease-in-out infinite;
}

.orange {
  background-color: #faf6f2;
}

.purple {
  background-color: #f4f5fa;
}

.pink-orange {
  background-color: #faf1f4;
}

.eof {
  display: flex;
  width: 100vw;
}

.color-box {
  height: 25px;
  width: 25vw;
}
.color-box.orange {
  background-color: #f3ab80;
}
.color-box.red {
  background-color: #ec5d3a;
}

.color-box.blue {
  background-color: #c4dae1;
}

.color-box.grey {
  background-color: #414247;
}

@media (max-width: 780px) {
  .eof {
    width: 120vw;
  }

  .color-box {
    width: 30vw;
  }
}
