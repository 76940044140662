.tech-container {
  border-radius: 20px;
  height: 24px;
  /* min-width: 80px; */
  width: auto;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.tech-icon {
  margin-left: 2px;
  background-color: white;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.ruby {
  color: #da685f;
  background-color: rgb(218, 104, 95, 30%);
}

.heroku {
  color: #6762a6;
  background-color: rgb(103, 98, 166, 30%);
}

.javascript {
  color: #c7b95e;
  background-color: rgb(240, 219, 79, 30%);
  width: 100px;
}

.sass {
  color: #cb6699;
  background-color: rgb(203, 102, 153, 30%);
}
.rails {
  background-color: rgb(166, 44, 70, 25%);
  color: rgb(166, 44, 70, 70%);
}

.react {
  background-color: rgb(97, 218, 251, 30%);
  color: rgb(36, 203, 249);
}

.postgresql {
  background-color: rgb(51, 103, 145, 30%);
  color: rgb(51, 103, 145);
}

.devicon-ruby-plain.colored {
  color: #da685f;
}

.tech-container i,
.tech-container span {
  padding-left: 3px;
  padding-right: 11px;
}

@media (max-width: 780px) {
  .tech-stack {
    width: 90vw;
  }
}
