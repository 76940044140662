.nav-content {
  margin-right: 0px !important;
}

.navbar-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.navbar-nav {
  align-items: flex-end;
}

.navbar {
  justify-content: flex-end;
  margin-right: 2vw;
  margin-top: 1vh;
}

.nav-link {
  color: black;
}

.nav-link:hover {
  font-weight: 400;
  color: black;
}

.footer {
  justify-content: center;
}

@media (max-width: 780px) {
  .footer {
    display: none;
  }
}
