.outro {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.outro .main {
  font-weight: bold;
  margin-bottom: 4vh;
}

.outro .location {
  color: #a9a9a9;
  font-size: 12px;
}

.outro .email {
  font-size: 18px;
}

.outro p {
  margin-bottom: 3px;
}

.social-links i {
  color: #404247;
  font-size: 21px;
}

.social-links i {
  color: #404247;
  font-size: 21px;
}

.social-links i:hover {
  color: #f3ab80;
}

.social-links {
  width: 80px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
