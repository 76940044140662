.getintouch h2 {
  font-weight: bold;
}

.getintouch {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.btn-flat.contact {
  background-color: #ec5d3a;
  color: #e5e5e7;
}
