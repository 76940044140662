.me img {
  width: 38vw;
  max-width: 550px;
  /* min-width: 300px; */
}

.intro-me {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: inherit;
}

.intro-me p {
  margin-bottom: 38px;
  max-width: 400px;
}

.btn-flat {
  margin-top: 20px;
  color: white;
  padding: 8px 24px;
  border-radius: 4px;
  background: #3c394a;
  transition: background 0.3s ease;
}

.btn-flat:hover {
  background: #4a465b;
  color: white;
}

.intro-text {
  color: #a9a9a9;
}

.intro-me h1 {
  margin-bottom: 23px;
  font-weight: 600;
}

@media (max-width: 780px) {
  .me img {
    width: 50vw;
  }

  .intro-content {
    margin-top: 20px;
    width: 50vw;
  }

  .intro-me {
    width: 100vw;
  }

  .intro-me p {
    margin-bottom: 18px;
    width: 232px;
    font-size: 15px;
  }

  .intro-me h1 {
    font-size: 30px;
    width: 232px;
  }
}

@media (max-width: 389px) {
  .me img {
    display: none;
  }

  .intro-content {
    margin-top: 20px;
    width: 100vw;
  }

  .intro-me p {
    width: 100vw;
    font-size: 15px;
  }

  .intro-me h1 {
    width: 100vw;
  }
}
