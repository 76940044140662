.project-container {
  display: flex;
  justify-content: space-around;
  background-color: #f5f9fa;
  border-radius: 20px;
  margin: 28px 0px 28px 0px;
  padding: 45px 0px 45px 45px;
  height: 388px;
  max-height: 388px;
  width: 1050px;
  max-width: 1050px;
  align-items: center;
}

.devicon-github-plain,
.fa-link {
  color: #404247;
  font-size: 21px;
  margin-left: 5px;
}

.project-content a {
  text-decoration: none;
}
.devicon-github-plain:hover {
  color: #61646b;
}

.photo-button {
  display: none;
}

.project-intro {
  padding-top: 50px;
  padding-bottom: 28px;
}

.project-intro h2 {
  font-size: 2rem;
  font-weight: 600;
}

.project-intro p {
  color: #a9a9a9;
}

.photos {
  width: 1000px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flipped .photos {
  justify-content: flex-end;
  margin-right: 14px;
}

.project-content {
  width: 400px;
  margin-left: 30px;
}

.project-content h3 {
  margin-top: 13px;
}

.project-content p {
  width: 300px;
}

.photos > img {
  width: 419px;
}

.photos .large {
  width: 596px;
}

.avatar {
  width: 40px !important ;
}

.ui.fluid.search.dropdown {
  width: 240px;
  margin-right: 32px;
  margin-left: 14px;
}

.ui.label {
  border-radius: 20px;
  height: 24px;
  width: auto;
  align-content: center;
}

.photos .medium {
  margin-right: 73px;
}

.project-filter-text {
  margin-right: 10px;
  font-size: 16px;
}

.project-filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 1200px) {
  .project-container {
    width: 980px;
    margin-left: -25px;
  }
}

@media (max-width: 991px) {
  .project-container {
    width: 743px;
    height: 350px;
    justify-content: space-between;
  }

  .ui.selection.dropdown .menu {
    height: auto;
    max-height: 300px;
  }
  .project-content {
    width: 305px;
  }

  .project-content p {
    width: 280px;
  }

  .photos {
    width: 313px;
  }

  .photos > img {
    width: 380.2px;
  }

  .photos .large {
    width: 406px;
    margin-right: -14px;
  }

  .photos .medium {
    margin-right: 0px;
  }
}

@media (max-width: 780px) {
  .project-content {
    margin-left: 0px;
  }

  .photo-button {
    display: block;
  }

  .project-container {
    width: 110vw;
    margin-left: 0px;
    height: 250px;
    min-width: 280px;
    margin: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 14vw;
  }
  .project-container .flipped {
    margin-left: 10px;
  }
  .photos img {
    display: none;
  }

  .project-content {
    margin-left: 0px;
  }

  .project-content p {
    width: 80vw;
  }

  .project-photo {
    width: 100%;
  }

  .fade.modal.show {
    display: flex !important;
  }

  .blue .photo-modal .btn-flat {
    background-color: #c4dbe1;
  }

  .orange .photo-modal .btn-flat {
    background-color: #f3ab7f;
  }

  .purple .photo-modal .btn-flat {
    background-color: #b9bfdf;
  }

  .photo-modal .btn-flat {
    height: 29px;
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ed6c4fcf;
    padding: 0px;
  }

  .modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .modal-body {
    /* display: flex; */
    /* align-items: center; */
    padding: 0px;
  }

  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    max-width: 100%;
  }
}
