.box {
  background-color: #faf6f2;
  margin-top: 50px;
  border-radius: 20px;
  padding: 4vh;
  align-content: center;
  width: 80vw;
  max-width: 795px;
}

.box h2 {
  font-size: 1.7rem;
}

.box p {
  color: #a9a9a9;
  font-size: 1rem;
}

@media (max-width: 780px) {
  .box {
    width: 100vw;
  }
}
